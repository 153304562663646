import { gsap } from "gsap";
import Splitting from "splitting";

const offCanvasMenu = document.querySelectorAll(".off-canvas-content");
console.log(offCanvasMenu);

const menuTriggers = document.querySelectorAll(".menu-trigger");
const menuClose = document.querySelectorAll(".menu-close");

menuTriggers.forEach((element) => {
  element.addEventListener("click", () => {
    offCanvasMenu[0].style.opacity = "1";
    offCanvasMenu[0].style.visibility = "visible";
  });
});

menuClose.forEach((element) => {
  element.addEventListener("click", () => {
    offCanvasMenu[0].style.opacity = "0";
    offCanvasMenu[0].style.visibility = "hidden";
  });
});

// document.querySelector(".menu-trigger").addEventListener("click", () => {
//   offCanvasMenu.style.opacity = "1";
//   offCanvasMenu.style.visibility = "visible";
// });

// document.querySelector(".menu-close").addEventListener("click", () => {
//   offCanvasMenu.style.opacity = "0";
//   offCanvasMenu.style.visibility = "hidden";
// });

// cursor animation

jQuery(document).ready(function ($) {
  $("body").prepend('<div class="cursor-dot-outline"></div>');
  $("body").prepend('<div class="cursor-dot"></div>');

  var cursor = {
    delay: 8,
    _x: 0,
    _y: 0,
    endX: window.innerWidth / 2,
    endY: window.innerHeight / 2,
    cursorVisible: true,
    cursorEnlarged: false,
    $dot: document.querySelector(".cursor-dot"),
    $outline: document.querySelector(".cursor-dot-outline"),

    init: function () {
      this.dotSize = this.$dot.offsetWidth;
      this.outlineSize = this.$outline.offsetWidth;

      this.setupEventListeners();
      this.animateDotOutline();
    },

    setupEventListeners: function () {
      var self = this;

      document.querySelectorAll("a").forEach(function (el) {
        el.addEventListener("mouseover", function () {
          self.cursorEnlarged = true;
          self.toggleCursorSize();
        });
        el.addEventListener("mouseout", function () {
          self.cursorEnlarged = false;
          self.toggleCursorSize();
        });
      });

      document.addEventListener("mousedown", function () {
        self.cursorEnlarged = true;
        self.toggleCursorSize();
      });
      document.addEventListener("mouseup", function () {
        self.cursorEnlarged = false;
        self.toggleCursorSize();
      });

      document.addEventListener("mousemove", function (e) {
        self.cursorVisible = true;
        self.toggleCursorVisibility();

        self.endX = e.clientX;
        self.endY = e.clientY;
        self.$dot.style.top = self.endY + "px";
        self.$dot.style.left = self.endX + "px";
      });

      document.addEventListener("mouseenter", function (e) {
        self.cursorVisible = true;
        self.toggleCursorVisibility();
        self.$dot.style.opacity = 1;
        self.$outline.style.opacity = 1;
      });

      document.addEventListener("mouseleave", function (e) {
        self.cursorVisible = true;
        self.toggleCursorVisibility();
        self.$dot.style.opacity = 0;
        self.$outline.style.opacity = 0;
      });
    },

    animateDotOutline: function () {
      var self = this;

      self._x += (self.endX - self._x) / self.delay;
      self._y += (self.endY - self._y) / self.delay;
      self.$outline.style.top = self._y + "px";
      self.$outline.style.left = self._x + "px";

      requestAnimationFrame(this.animateDotOutline.bind(self));
    },

    toggleCursorSize: function () {
      var self = this;

      if (self.cursorEnlarged) {
        self.$dot.style.transform = "translate(-50%, -50%) scale(4)";
        self.$outline.style.transform = "translate(-50%, -50%) scale(0)";
      } else {
        self.$dot.style.transform = "translate(-50%, -50%) scale(1)";
        self.$outline.style.transform = "translate(-50%, -50%) scale(1)";
      }
    },

    toggleCursorVisibility: function () {
      var self = this;

      if (self.cursorVisible) {
        self.$dot.style.opacity = 1;
        self.$outline.style.opacity = 1;
      } else {
        self.$dot.style.opacity = 0;
        self.$outline.style.opacity = 0;
      }
    },
  };
  cursor.init();
});
// arrow button

const buttonArrows = document.querySelectorAll("a.btn-arrow");
buttonArrows.forEach((buttonArrow) => {
  const buttonArrowText = document.querySelector(
    `#${buttonArrow.id} .btn-arrow-text`,
  );
  Splitting({ target: buttonArrowText, by: "chars" });
  const buttonArrowTimeline = gsap
    .timeline({ paused: true })
    .to(`#${buttonArrow.id} .btn-arrow-text .char`, {
      y: -20,
      duration: 0.15,
      stagger: 0.12,
    })
    .to(`#${buttonArrow.id} .btn-arrow-text .char`, {
      y: 20,
      duration: 0,
    })
    .to(`#${buttonArrow.id} .btn-arrow-text .char`, {
      y: 0,
      duration: 0.15,
      stagger: 0.12,
    });
  buttonArrow.addEventListener("mouseover", () => {
    buttonArrowTimeline.play();
  });

  buttonArrow.addEventListener("mouseout", () => {
    buttonArrowTimeline.reverse();
  });
});
